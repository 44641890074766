import { twMerge } from '@troon/tailwind-preset/merge';
import type { ParentProps, JSX } from 'solid-js';

type Props = ParentProps<
	JSX.HTMLAttributes<HTMLDivElement> & {
		small?: boolean;
	}
>;

export function Content(props: Props) {
	return (
		<div
			{...props}
			class={twMerge(
				'mx-auto w-full px-4 sm:px-6 md:px-12 xl:px-24',
				props.small ? 'container max-w-screen-xl' : 'container',
				props.class,
			)}
		/>
	);
}
